import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer';
import Header from '../components/Header';
import StripePayment from '../components/StripePayment';
import { startTrialAsync } from '../redux/actionCreators/thunks/userThunk';
const Subscribe = () => {
  let navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.user || {});
  const [email, setEmail] = useState(userInfo.email || "");
  const dispatch = useDispatch();
  const handleButtonClick = () => {
    dispatch(startTrialAsync(email));
    navigate("/");
  }
  return (
    <>
      <Header />
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="full"
        height="60vh"
        backgroundColor="gray.50"
      >
        <VStack
          spacing={4}
          p={5}
          backgroundColor="white"
          boxShadow="md"
          borderRadius="lg"
          w="sm"
          align={"center"}
        >
          <Heading size="lg">Purchase Membership</Heading>

          {userInfo["trialUsed"] === true && <>
            <VStack spacing={3} align="center" w="full" py={4}>
              <Text fontSize="lg" fontWeight="bold">
                Monthly $1.00
              </Text>
            </VStack>
            <StripePayment />
          </>}

          {userInfo["trialUsed"] === false && <Button type="submit" colorScheme="blue" w="full" bgColor="#000000" onClick={() => handleButtonClick()}>Start Trial</Button>}
        </VStack>



      </Box>
      <Footer />
    </>
  )
}

export default Subscribe;
