import React from "react";

const Footer = () => {
  return (
    <footer className="bg-custom-gray text-gray-300 py-5">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center flex flex-col  justify-center md:flex-row  space-y-8 md:space-y-0">
          <div className="flex  flex-col space-y-4 text-center">
            <h5 className="text-lg text-white">About ATH</h5>
            <p className="text-sm text-gray-400 md:max-w-xs ">
              Australian Transport Hub provides a "no fuss" approach to
              connecting Clients with Carriers, making it simple for you to get
              your goods delivered at the best price!
            </p>
          </div>
        </div>

        {/* Contact Us Link */}
        <div className="flex justify-center my-4">
          <a
            href="mailto:support@transporthub.com.au"
            className="text-sm text-gray-400 underline"
          >
            Contact Us
          </a>
        </div>
        <div className="flex justify-center my-4">
        </div>

        {/* Horizontal Line */}
        <div className="border-t border-gray-400 my-8 w-full"></div>

        {/* Copyright Section */}
        <p className="text-center text-sm text-gray-400">
          Copyright © 2024 - Australian Transport Hub
        </p>
      </div>
    </footer>
  );
};

export default Footer;
