import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Checkbox,
  Heading,
  useToast,
  InputGroup,
  InputLeftAddon,
  Stack,
  Center,
  FormErrorMessage,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import { addJobAsync, fetchJobsAsync } from "../redux/actionCreators/thunks/jobsThunk";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatarImage from "../assets/no_image.png";
import { b64ToBlob } from "../redux/actionCreators/thunks/helperFunctions";
import Autocomplete from "../components/AutoComplete";
import { LoadScript } from '@react-google-maps/api';
const PostNewJob = () => {
  let navigate = useNavigate();
  const usersInfo = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [numberOfPackages, setNumberOfPackages] = useState("");
  const [pickupDate, setPickupDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [pickupisError, setPickupIsError] = useState(false);
  const [deliveryisError, setDeliveryIsError] = useState(false);
  const [selectedPickupMainRegion, setSelectedPickupMainRegion] = useState(null);
  const [selectedPickupSubRegion, setSelectedPickupSubRegion] = useState(null);
  const [selectedDeliveryMainRegion, setSelectedDeliveryMainRegion] = useState(null);
  const [selectedDeliverySubRegion, setSelectedDeliverySubRegion] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setPickupIsError(selectedPickupMainRegion === null);
  }, [selectedPickupMainRegion]);
  useEffect(() => {
    setDeliveryIsError(selectedDeliveryMainRegion === null);
  }, [selectedDeliveryMainRegion]);



  const initialRegions = [
    {
      mainCategory: "Victoria",
      subCategories: [
        "North VIC",
        "East VIC",
        "West VIC",
        "Central-Greater Melbourne VIC",
      ],
    },
    {
      mainCategory: "New South Wales",
      subCategories: [
        "South NSW",
        "North NSW",
        "West NSW",
        "Greater Sydney NSW",
      ],
    },
    {
      mainCategory: "South Australia",
      subCategories: ["North SA", "East SA", "West SA", "Greater Adelaide SA"],
    },
    {
      mainCategory: "Western Australia",
      subCategories: ["North WA", "Central WA", "South WA", "Greater Perth WA"],
    },
    {
      mainCategory: "Northern Territory",
      subCategories: ["North Greater Darwin NT", "Central NT", "South NT"],
    },
    { mainCategory: "Tasmania", subCategories: [] },
    {
      mainCategory: "Queensland",
      subCategories: [
        "North QLD",
        "Far North QLD",
        "Central QLD",
        "South QLD",
        "SE Corner Greater Brisbane QLD",
      ],
    },
  ];
  const toast = useToast();

  const handleSubmit = async (e) => {
    const googleMapsApiKey = "AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks";
    e.preventDefault();
    if (selectedDeliveryMainRegion === null) {
      setDeliveryIsError(true);
    } else if (selectedPickupMainRegion === null) {
      setPickupIsError(true);
    } else {
      setPickupIsError(false);
      setDeliveryIsError(false);
      // Handle form submission logic here
      toast({
        title: "Job Posted",
        description: "Your job has been posted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      let dist = await findDistanceBetweenAddresses(pickupAddress, deliveryAddress, googleMapsApiKey)
      try {
        dist = dist["rows"][0]["elements"][0]["distance"]["text"].replace("km", "")
      } catch {
        dist = 0
      }

      let form = {
        title: jobTitle,
        description: jobDescription,
        postedDate: new Date(),
        image: file ? file : await b64ToBlob(avatarImage),
        length: length,
        width: width,
        weight: weight,
        height: height,
        numberOfPackages: numberOfPackages,
        pickupLocation: selectedPickupSubRegion === null ? [selectedPickupMainRegion] : [selectedPickupMainRegion, selectedPickupSubRegion],
        pickupAddress: pickupAddress,
        deliveryLocation: selectedDeliverySubRegion === null ? [selectedDeliveryMainRegion] : [selectedDeliveryMainRegion, selectedDeliverySubRegion],
        deliveryAddress: deliveryAddress,
        pickupDate: pickupDate,
        deliveryDate: deliveryDate,
        distance: dist,
        createdBy: [usersInfo["name"], usersInfo["email"], usersInfo["avatar"]],
      };
      dispatch(addJobAsync(form));
      dispatch(fetchJobsAsync());
      navigate("/dashboard");
    }
  };

  async function findgeocodeAddress(address, googleMapsApiKey) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        return data.results[0].geometry.location; // Returns { lat, lng }
      } else {
        console.error('Geocoding failed:', data.error_message);
        return null;
      }
    } catch (error) {
      console.error('Geocoding request failed:', error);
      return null;
    }
  }


  async function getDistance(origin, destination, googleMapsApiKey) {
    const origins = `${origin.lat},${origin.lng}`;
    const destinations = `${destination.lat},${destination.lng}`;

    try {
      const dbUrl = process.env.REACT_APP_DB_URL;
      const response = await fetch(dbUrl + `getDistance?origins=${origins}&destinations=${destinations}&key=${googleMapsApiKey}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  async function findDistanceBetweenAddresses(address1, address2, googleMapsApiKey) {
    const location1 = await findgeocodeAddress(address1 + "Australia", googleMapsApiKey);
    const location2 = await findgeocodeAddress(address2 + "Australia", googleMapsApiKey);

    if (location1 && location2) {
      return await getDistance(location1, location2, googleMapsApiKey);
    } else {
      return null;
    }
  }
  const handleMainRegionChange = (mainCategory, setregion, setsubregion, selectedMainRegion) => {
    if (selectedMainRegion === mainCategory) {
      setregion(null);
      setregion(null);
    } else {
      setregion(mainCategory);
      setsubregion(null);
    }
  };
  const handleSubRegionChange = (subCategory, setregion) => {
    setregion(subCategory);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Store the actual file object
      setFileName(selectedFile.name); // Store the file name for display
    }
  };
  const handleRemoveFile = () => {
    setFile(null); // Clear the file object
    setFileName(""); // Clear the file name
  };
  return (
    <>
      <Header />
      <NavBar />
      <Center minH="100vh" bg="gray.100" p={4}>
        <Box
          as="form"
          onSubmit={handleSubmit}
          p={{ base: 4, sm: 8 }} // Adjust padding as needed
          width="full"
          maxW="5xl" // Set a maximum width to make the form less wide
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="xl"
          bg="white"
          mx="auto" // Add this to center the Box horizontally if not using <Center>
        >
          <VStack onSubmit={handleSubmit} spacing={4} align="stretch">
            <Heading alignSelf={"center"} as="h3" size="lg">
              Post New Job
            </Heading>

            <FormControl isRequired>
              <FormLabel htmlFor="jobTitle">Your job title:</FormLabel>
              <Input
                id="jobTitle"
                placeholder="eg: I need a package moved."
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor="jobDescription">
                Your job description:
              </FormLabel>
              <Textarea
                id="jobDescription"
                placeholder="Describe here your shipping job scope."
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />
            </FormControl>

            <Stack
              direction={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium screens and up
              spacing={4} // Adjust spacing as needed
              width="full" // Ensure it takes the full width
            >
              <FormControl isRequired>
                <FormLabel htmlFor="length">Length:</FormLabel>
                <InputGroup>
                  <Input
                    id="length"
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                  <InputLeftAddon children="mm" />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="width">Width:</FormLabel>
                <InputGroup>
                  <Input
                    id="width"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                  <InputLeftAddon children="mm" />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="height">Height:</FormLabel>
                <InputGroup>
                  <Input
                    id="height"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                  <InputLeftAddon children="mm" />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="weight">Weight:</FormLabel>
                <InputGroup>
                  <Input
                    id="weight"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                  <InputLeftAddon children="KG" />
                </InputGroup>
              </FormControl>
            </Stack>

            <FormControl isRequired>
              <FormLabel htmlFor="numberOfPackages">
                Number of packages:
              </FormLabel>
              <Input
                id="numberOfPackages"
                placeholder="eg: 2"
                value={numberOfPackages}
                onChange={(e) => setNumberOfPackages(e.target.value)}
              />
            </FormControl>


            <FormControl isInvalid={pickupisError}>
              <FormLabel htmlFor="pickupLocation">PACKAGE PICKUP Region</FormLabel>
              <Box maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md" p={2}>
                <VStack align="stretch" spacing={1}>
                  {initialRegions.map((category) => (
                    <Box key={category.mainCategory}>
                      <Checkbox
                        isChecked={selectedPickupMainRegion === category.mainCategory}
                        onChange={() => handleMainRegionChange(category.mainCategory, setSelectedPickupMainRegion, setSelectedPickupSubRegion, selectedPickupMainRegion)}
                      >
                        {category.mainCategory}
                      </Checkbox>
                      {selectedPickupMainRegion === category.mainCategory && (
                        <Stack pl={4} mt={1} spacing={1}>
                          {category.subCategories.map((subCategory) => {
                            return (
                              <Checkbox
                                key={subCategory}
                                isChecked={selectedPickupSubRegion === subCategory}
                                onChange={() => handleSubRegionChange(subCategory, setSelectedPickupSubRegion)}
                              >
                                {subCategory}
                              </Checkbox>
                            );
                          })}
                        </Stack>
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
              <FormErrorMessage>
                A main region must be selected.
              </FormErrorMessage>
            </FormControl>
            <LoadScript googleMapsApiKey={"AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"} libraries={['places']}>
              <FormControl isRequired>
                <FormLabel htmlFor="pickupaddress">Pickup Address</FormLabel>
                <Autocomplete value={pickupAddress} onChange={setPickupAddress} title="Pickup Address" />
              </FormControl>
            </LoadScript>

            <FormControl isRequired>
              <FormLabel htmlFor="pickupDate">Pickup Date</FormLabel>
              <DatePicker
                id="pickupDate"
                selected={pickupDate}
                onChange={(date) => setPickupDate(date)}
                placeholderText="Click here to choose date"
                dateFormat="dd/MM/yyyy"
                customInput={
                  <Input
                    placeholder="Click here to choose date"
                    _hover={{ borderColor: "gray.200" }}
                    _focus={{ borderColor: "gray.200" }}
                    borderColor="gray.200"
                    w="full"
                  />
                }
              />
            </FormControl>

            <FormControl isInvalid={deliveryisError}>
              <FormLabel htmlFor="deliveryLocation">
                PACKAGE DELIVERY REGION
              </FormLabel>
              <Box
                maxH="200px" // Adjust the height as needed
                overflowY="auto"
                borderWidth="1px"
                borderRadius="md"
                p={2}
              >
                <VStack align="stretch" spacing={1}>
                  <VStack align="stretch" spacing={1}>
                    {initialRegions.map((category) => (
                      <Box key={category.mainCategory}>
                        <Checkbox
                          isChecked={selectedDeliveryMainRegion === category.mainCategory}
                          onChange={() => handleMainRegionChange(category.mainCategory, setSelectedDeliveryMainRegion, setSelectedDeliverySubRegion, selectedDeliveryMainRegion)}
                        >
                          {category.mainCategory}
                        </Checkbox>
                        {selectedDeliveryMainRegion === category.mainCategory && (
                          <Stack pl={4} mt={1} spacing={1}>
                            {category.subCategories.map((subCategory) => {
                              return (
                                <Checkbox
                                  key={subCategory}
                                  isChecked={selectedDeliverySubRegion === subCategory}
                                  onChange={() => handleSubRegionChange(subCategory, setSelectedDeliverySubRegion)}
                                >
                                  {subCategory}
                                </Checkbox>
                              );
                            })}
                          </Stack>
                        )}
                      </Box>
                    ))}
                  </VStack>
                </VStack>
              </Box>
              <FormErrorMessage>
                A main region must be selected.
              </FormErrorMessage>
            </FormControl>

            <LoadScript googleMapsApiKey={"AIzaSyBtDGQnr6t1ZzQcP3CWfmi4nrC80m1Rfks"} libraries={['places']}>
              <FormControl isRequired>
                <FormLabel htmlFor="deliveryaddress">Delivery Address</FormLabel>
                <Autocomplete value={deliveryAddress} onChange={setDeliveryAddress} title="Delivery Address" />
              </FormControl>
            </LoadScript>


            <FormControl isRequired>
              <FormLabel htmlFor="deliveryDate">Delivery Date</FormLabel>
              <DatePicker
                id="DeliveryDate"
                selected={deliveryDate}
                onChange={(date) => setDeliveryDate(date)}
                placeholderText="Click here to choose date"
                dateFormat="dd/MM/yyyy"
                customInput={
                  <Input
                    placeholder="Click here to choose date"
                    _hover={{ borderColor: "gray.200" }}
                    _focus={{ borderColor: "gray.200" }}
                    borderColor="gray.200"
                    w="full"
                  />
                }
              />
            </FormControl>
            <Heading as="h4" size="md">
              Add Photo
            </Heading>
            <Text>
              Click the grey area below to add job images. Other files are not
              accepted. Use the form below.
            </Text>
            <Box
              p={5}
              borderWidth="2px"
              borderRadius="md"
              borderColor="gray.300"
              borderStyle="dashed"
              bg="gray.100"
              textAlign="center"
            >
              <FormLabel>
                <Input
                  type="file"
                  height="0"
                  width="0"
                  overflow="hidden"
                  onChange={handleFileChange}
                  accept="image/*" // Accept only images
                />
                <Text fontSize="lg" fontWeight="bold">
                  Click to upload files
                </Text>
                <Text fontSize="sm">
                  {fileName || "No file selected. Max File size 4mb"}
                </Text>
                {fileName && (
                  <Button onClick={handleRemoveFile}> Remove</Button>
                )}
              </FormLabel>
            </Box>
            <Button colorScheme="blue" bgColor="#000000" type="submit">
              Post Job
            </Button>
          </VStack>
        </Box>
      </Center>
      <Footer />
    </>
  );
};

export default PostNewJob;
