import {
  Center,
  Image,
  Box,
  Flex,
  IconButton,
  Stack,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logoutAsync } from "../redux/actionCreators/thunks/authThunk";


const Header = ({ handleScroll }) => {
  const usersInfo = useSelector((state) => state.auth.user || "");
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  // useEffect(() => {
  //   dispatch(initApp())
  // });

  const handleLoginClick = () => {
    navigate("/login"); // Use navigate to change the route
  };
  const handleRegisterClick = () => {
    navigate("/register"); // Use navigate to change the route
  };
  const handleHomeClick = () => {
    navigate("/"); // Use navigate to change the route
  };
  const handleDashboardClick = () => {
    if (isAuth) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  const handlePostClick = () => {
    if (isAuth) {
      navigate("/postnewjob");
    } else {
      navigate("/login");
    }
  };
  const handleJobClick = () => {
    if (isAuth) {
      navigate("/alljobs");
    } else {
      navigate("/login");
    }
  };
  const handleLogOutClick = () => {
    dispatch(logoutAsync());
    navigate("/");

  };
  const handlePhoneShortcut = () => {
    window.open("https://faq.transporthub.com.au/", "_blank");
  };

  return (
    <Box px={4} pt={2}>
      <Flex h={20} alignItems={"center"} justifyContent={"space-between"}>
        {" "}
        {/* Increased header height */}
        {/* Hamburger Menu Icon for Mobile */}
        <IconButton
          size={"md"}
          icon={<HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={onOpen}
        />
        {/* Logo Text or Image */}
        {/* Centering the logo image in the Box and applying appropriate padding */}
        <Center width={{ base: "150px", md: "300px" }} height="100%">
          <Image
            src={`${process.env.PUBLIC_URL}/Australian-Transport-Hub-Logo-Web.png`}
            alt="Logo"
            objectFit="contain"
            maxW="100%"
            maxH="100%"
            onClick={handleHomeClick}
          />
        </Center>
        {/* Login and Register Buttons */}
        <Stack
          direction={"row"}
          spacing={4}
          display={{ base: "none", md: "flex" }}
        >
          <Button
            onClick={handlePhoneShortcut}
            bg={"RGB(242, 13, 13,1)"}
            colorScheme="red"
          >
            Add Phone Shortcut
          </Button>
          {location.pathname === "/" && (
            <>

              <Button
                onClick={handleScroll}
                bg={"RGB(242, 13, 13,1)"}
                colorScheme="red"
              >
                Go to Jobs
              </Button>

            </>
          )}
          {!isAuth && (
            <Button
              onClick={handleLoginClick}
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
            >
              Login
            </Button>
          )}
          {!isAuth && (
            <Button
              onClick={handleRegisterClick}
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
            >
              Register
            </Button>
          )}
          {isAuth && (
            <Button
              onClick={handleLogOutClick}
              bg={"RGB(242, 13, 13,1)"}
              colorScheme="red"
            >
              Log Out
            </Button>
          )}
        </Stack>
      </Flex>

      {/* Drawer for Mobile Menu */}
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Navigation</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <Button onClick={handleHomeClick} w="full" variant="ghost">
                Home
              </Button>
              <Button onClick={handleJobClick} w="full" variant="ghost">
                All Jobs
              </Button>
              <Button w="full" variant="ghost" onClick={handleDashboardClick}>
                My Acount Area
              </Button>
              <Button
                onClick={handlePhoneShortcut}
                w="full" variant="ghost"
              >
                Add Phone Shortcut
              </Button>
              {usersInfo.type === "customer" &&
                <Button w="full" variant="ghost" onClick={handlePostClick}>
                  Post New Job
                </Button>
              }
              {isAuth && (
                <Button w="full" variant="ghost" onClick={handleLogOutClick}>
                  Log Out
                </Button>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;
